<template>
  <a-spin tip="Loading..." :spinning="formLoading">
    <a-form-model
      :loading="formLoading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label='平级佣金' prop='samePercent' extra="平级分红，0-1之间小数，0不平级分红">
        <a-input-number :precision="2" :min='0' :max='0.99' style='width:100%;' v-model="form.samePercent" placeholder='请输入'></a-input-number>
      </a-form-model-item>
      <a-row type='flex' align='middle' justify="center">
        <a-button type="primary" :loading='submitLoading' @click="onSubmit">保存</a-button>
      </a-row>
    </a-form-model>
  </a-spin>
</template>
<script>
import { agent } from '@/api'
export default {
  name: 'ParameterForm',
  data: () => ({
    agent,
    shopId: '',
    formLoading: false,
    submitLoading: false,
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
    form: {
      samePercent: 0,
    },
    rules: {
      samePercent: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
    }
  }),
  async mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      this.formLoading = true
      let { code, msg, data } = await agent.getParameter()
      this.formLoading = false
      if (code === '00000') {
        if (data != null) {
          this.form = {
            paramId: data.paramId,
            shopId: data.shopId,
            samePercent: data.samePercent,
          }
        }
      } else {
        this.$message.error(msg || '网络错误，请重试')
      }
    },

    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.saveParameter()
        } else {
          return false
        }
      })
    },
    async saveParameter () {
      this.submitLoading = true
      let { code, data, msg } = await agent.saveParameter(this.form)
      this.submitLoading = false
      if (code === '00000') {
        if (this.form.paramId) {
          this.$message.success('修改成功')
        } else {
          this.form.paramId = data.paramId
          this.$message.success('创建成功')
        }
      } else {
        this.$message.error(msg || '创建失败')
      }
    },
  }
}
</script>
<style lang="less" scoped>
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
</style>
